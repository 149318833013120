import * as React from 'react';

//Styling
import './GameGrid.scss';

//Child components
import Game from './components/Game/Game';
import Controls from './components/Controls/Controls';

const operations = [
    [0, 1],
    [0, -1],
    [1, -1],
    [-1, 1],
    [1, 1],
    [-1, -1],
    [1, 0],
    [-1, 0]
];

const generateEmptyGrid = () => {
    const rows = [];
    for (let i = 0; i < 40; i++) {
        rows.push(Array.from(Array(40), () => 0));
    }

    return rows;
};


const GameGrid = () => {

    const [grid, setGrid] = React.useState(generateEmptyGrid());
    const [running, setRunning] = React.useState(false);

    const runningRef = React.useRef(running);
    runningRef.current = running;

    const runSimulation = React.useCallback(() => {
        if (!runningRef.current) {
            return;
        }

        setGrid(g => {
            let gridCopy = JSON.parse(JSON.stringify(g));
            for (let i = 0; i < 40; i++) {
                for (let j = 0; j < 40; j++) {
                    let neighbors = 0;
                    operations.forEach(([x, y]) => {
                        const newI = i + x;
                        const newJ = j + y;
                        if (newI >= 0 && newI < 40 && newJ >= 0 && newJ < 40) {
                            neighbors += g[newI][newJ]
                        }
                    });

                    if (neighbors < 2 || neighbors > 3) {
                        gridCopy[i][j] = 0;
                    } else if (g[i][j] === 0 && neighbors === 3) {
                        gridCopy[i][j] = 1
                    }
                }
            }
            return gridCopy;
        })

        setTimeout(runSimulation, 100);
    }, [])

    return (
        <div className="gamegrid-wrapper">
            <div className="gamegrid-game">
                <Game grid={grid} updateGrid={(x: number, y: number) => { const newGrid = JSON.parse(JSON.stringify(grid)); newGrid[x][y] = grid[x][y] ? 0 : 1; setGrid(newGrid) }} />
            </div>
            <div className="gamegrid-controls">
                <Controls resetGame={() => { setGrid(generateEmptyGrid()) }} startGame={() => { setRunning(!running); if (!running) { runningRef.current = true; runSimulation(); } }} gameState={running} />
            </div>
        </div>
    )
}

export default GameGrid;