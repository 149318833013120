import * as React from 'react';

//Styling
import './Footer.scss';

const Footer = () => {
    return(
        <div className="footer-wrapper">
            <span>version developed by <a href="https://joanboix.dev">joan boix àvalos</a></span>
        </div>
    )
}

export default Footer;