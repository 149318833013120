import * as React from 'react';

//Styling
import './Controls.scss';

type Props = {
    resetGame: () => void,
    startGame: () => void,
    gameState: boolean
}

const Controls = (props:Props) => {

    const getStartButtonText = () => {
        if(!props.gameState) return "start"
        return "stop"
    }

    return (
        <div className="controls-wrapper">
            <div className="controls-button">
                <span onClick={props.resetGame}>reset</span>
            </div>
            <div>
                <span onClick={props.startGame}>{getStartButtonText()}</span>
            </div>
        </div>
    )
}

export default Controls;