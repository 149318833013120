import * as React from 'react';

//Styling
import './Game.scss';

//Child components
import Cell from './components/Cell/Cell';

type Props = {
    grid: number[][],
    updateGrid: (coordX:number, coordY: number, active: boolean) => void;
}

const Game = (props:Props) => {
    return(
        <div className="game-wrapper">
            {
                props.grid.map((row: number[], x: number) => {
                    return row.map((item: number, y: number) => {
                        return(
                            <Cell key={x+" "+y} active={item === 1} coordsX={x} coordsY={y} updateGrid={props.updateGrid} ></Cell>
                        )
                    });
                })
            }
        </div>
    )
}

export default Game;