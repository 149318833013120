import * as React from 'react';

//Styling 
import './Cell.scss';

type Props = {
    coordsX: number,
    coordsY: number,
    active: boolean,
    updateGrid: (coordX:number, coordY: number, active: boolean) => void;
}

const Cell = (props:Props) => {
    return(
        <div onClick={()=>props.updateGrid(props.coordsX, props.coordsY, props.active)} className={"cell-wrapper " + (props.active ? "cell-active" : "")} >
        </div>
    )
}

export default Cell;