import React from 'react';

//Styling
import './App.scss';

//Child components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import GameGrid from './components/GameGrid/GameGrid';

function App() {
  return (
    <div className="app-wrapper">
      <Header />
      <GameGrid />
      <Footer />
    </div>
  );
}

export default App;
