import * as React from 'react';

//Styling
import './Header.scss';

const Header = () => {
    return(
        <div className="header-wrapper">
            <span>game of life</span>
        </div>
    )
}

export default Header;